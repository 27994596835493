$color-primary: #00ABED;
$color-branding: #0030C0;
$color-dark-8: rgba(15, 35, 67, 0.08);
$element-border-radius: 16px;

// Selects sidebar background
.redoc-wrap > div:last-child {
     display: none;
}

// Rounded corners for request/response samples
.api-content > div > div >div:nth-child(2) {
     border-radius: $element-border-radius;
     padding: 20px;
     margin: 30px;

     padding: 32px 24px;
     background-color: #fff;
     box-shadow: 0 5px 30px 0 #ddd;
}

.api-info > p {
     a {
          border: none;
          border-radius: 40px;
          color: white;
          background-color: $color-primary;
          font-weight: bold;
          padding: 8px;
     }
}

span[type] {
     border-radius: 8px;
}

label[role="menuitem"] {
     font-size: 1em;
}