body {
  margin: 0;
  font-family: 'Proxima Nova';
  src: url('./fonts/ProximaNova-Regular.woff') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-weight: 900;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('./fonts/ProximaNova-Black.woff2') format('woff2'),
      url('./fonts/ProximaNova-Black.woff') format('woff'),
      url('./fonts/ProximaNova-Black.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-weight: 800;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('./fonts/ProximaNova-Extrabld.woff2') format('woff2'),
      url('./fonts/ProximaNova-Extrabld.woff') format('woff'),
      url('./fonts/ProximaNova-Extrabld.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-weight: 700;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('./fonts/ProximaNova-Bold.woff2') format('woff2'),
      url('./fonts/ProximaNova-Bold.woff') format('woff'),
      url('./fonts/ProximaNova-Bold.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-weight: 600;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('./fonts/ProximaNova-Semibold.woff2') format('woff2'),
      url('./fonts/ProximaNova-Semibold.woff') format('woff'),
      url('./fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-weight: 400;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('./fonts/ProximaNova-Regular.woff2') format('woff2'),
      url('./fonts/ProximaNova-Regular.woff') format('woff'),
      url('./fonts/ProximaNova-Regular.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-weight: 400;
  font-family: 'Proxima Nova';
  font-style: italic;
  src: url('./fonts/ProximaNova-RegularIt.woff2') format('woff2'),
      url('./fonts/ProximaNova-RegularIt.woff') format('woff'),
      url('./fonts/ProximaNova-RegularIt.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-weight: 300;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('./fonts/ProximaNova-Light.woff2') format('woff2'),
      url('./fonts/ProximaNova-Light.woff') format('woff'),
      url('./fonts/ProximaNova-Light.ttf') format('truetype');
  font-display: fallback;
}

